import React from "react"
import PropTypes from "prop-types"
import Navigation from "./navigation"
import Footer from "./footer"

const Layout = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <script
        type="text/javascript"
        src="https://secure.sour7will.com/js/214371.js"
      />
      <noscript>
        <img
          alt=""
          src="https://secure.sour7will.com/214371.png"
          style={{
            display: "none",
          }}
        />
      </noscript>
      <header>
        <Navigation />
      </header>
      <main className="flex-grow mt-24 md:mt-28">{children}</main>
      <footer className="bg-wpt-grey py-5 mt-8 md:py-16">
        <Footer />
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
