module.exports = {
  siteMetadata: {
    title: "WELL PLUS TRADE GmbH",
    description: `More Fitness and Health`,
    description_de: `Mehr Fitness und Gesundheit`,
    siteUrl: `https://www.wellplustrade.com`,
    pageImage: `/wpt-logo.png`,
    author: "Well Plus Trade GmbH",
    languages: ["de", "en"],
    defaultLanguage: "en",
    templatePages: [
      // [regex for slug field, template file path]
      [
        "/what-we-do/[a-z0-9_-]+/?",
        `${__dirname}/src/templates/what-we-do-page.js`,
      ],
      [
        "/products/[a-z0-9_-]+/?",
        `${__dirname}/src/templates/products-page.js`,
      ],
      [
        "/news/[a-z0-9_-]+/?",
        `${__dirname}/src/templates/news-article-page.js`,
      ],
    ],
  },
  plugins: [
    "gatsby-plugin-sharp",
    "gatsby-transformer-sharp",
    {
      resolve: "gatsby-transformer-remark",
      options: {
        plugins: [
          "gatsby-remark-relative-images",
          {
            resolve: "gatsby-remark-images",
            options: {
              maxWidth: 1024,
              showCaptions: false,
            },
          },
          {
            resolve: "gatsby-remark-copy-linked-files",
            options: {
              destinationDir: "static",
            },
          },
        ],
      },
    },
    "gatsby-plugin-postcss",
    "gatsby-plugin-fontawesome-css",
    "gatsby-plugin-react-helmet",
    {
      resolve: "gatsby-source-filesystem",
      options: {
        name: "images",
        path: `${__dirname}/src/images`,
      },
    },
    {
      resolve: "gatsby-source-filesystem",
      options: {
        path: `${__dirname}/static/static`,
        name: "uploads",
      },
    },
    {
      resolve: "gatsby-source-filesystem",
      options: {
        path: `${__dirname}/src/pages`,
        name: "pages",
      },
    },
    "gatsby-plugin-image",
    {
      resolve: "gatsby-plugin-i18n",
      options: {
        langKeyDefault: "en",
        useLangKeyLayout: false,
        prefixDefault: false,
      },
    },
    {
      resolve: `gatsby-plugin-feed`,
      options: {
        query: `
          {
            site {
              siteMetadata {
                title
                description
                siteUrl
              }
            }
          }
        `,
        feeds: [
          {
            serialize: ({ query: { site, allMarkdownRemark } }) => {
              return allMarkdownRemark.edges.map(edge => {
                return Object.assign({}, edge.node.frontmatter, {
                  description: edge.node.excerpt,
                  date: edge.node.frontmatter.date,
                  url: site.siteMetadata.siteUrl + edge.node.fields.slug,
                  guid: site.siteMetadata.siteUrl + edge.node.fields.slug,
                  custom_elements: [{ "content:encoded": edge.node.html }],
                })
              })
            },
            query: `
              {
                  allMarkdownRemark(
                    filter: {
                      fields: {
                        slug: { regex: "//news/[a-z0-9_-]+/?/" }
                        langKey: { eq: "en" }
                      }
                      frontmatter: { linkedin: { eq: true } }
                    }
                    sort: { fields: frontmatter___date, order: DESC }
                  ) {
                    edges {
                      node {
                        frontmatter {
                          title
                          date
                        }
                        excerpt(pruneLength: 750, truncate: false)
                        html
                        fields {
                          slug
                        }
                      }
                    }
                  }
                }
            `,
            output: "/rss.xml",
            title: "WELL PLUS TRADE GmbH News",
            // optional configuration to insert feed reference in pages:
            // if `string` is used, it will be used to create RegExp and then test if pathname of
            // current page satisfied this regular expression;
            // if not provided or `undefined`, all pages will have feed reference inserted
            match: "/news/[a-z0-9_-]+/?",
          },
        ],
      },
    },
    "gatsby-plugin-sitemap",
    {
      resolve: "gatsby-plugin-netlify-cms",
      options: {
        modulePath: `${__dirname}/src/cms/cms.js`,
        customizeWebpackConfig: config => {
          config.plugins = config.plugins.filter(
            plugin => plugin.constructor.name !== "StaticQueryMapper"
          )
        },
      },
    },
    // Must be the last plugin loaded
    "gatsby-plugin-netlify",
  ],
}
