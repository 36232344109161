import React from "react"
import LocalizedLink from "./localized-link"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faInstagram,
  faLinkedin,
  faXing,
} from "@fortawesome/free-brands-svg-icons"
import { FormattedMessage, useIntl } from "react-intl"
import footerData from "../data/footer.json"

const Footer = () => {
  const { locale, defaultLocale } = useIntl()
  let parsedFooterData
  if (footerData.hasOwnProperty(locale)) {
    parsedFooterData = footerData[locale]
  } else {
    parsedFooterData = footerData[defaultLocale]
  }

  return (
    <div className="container mx-auto px-5 divide-y-2 divide-gray-900">
      <div className="flex flex-col space-y-6 pb-6 md:flex-row md:space-x-10 md:space-y-0">
        <p className="font-medium">WELL PLUS TRADE GmbH</p>
        <div className="flex-grow">
          <div className="flex space-x-10 font-light">
            <ul>
              <li>
                <LocalizedLink
                  to="/"
                  className="transition hover:text-gray-600"
                >
                  <FormattedMessage id="home" defaultMessage="Home" />
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink
                  to="/news/"
                  className="transition hover:text-gray-600"
                >
                  <FormattedMessage id="news" defaultMessage="News" />
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink
                  to="/about/"
                  className="transition hover:text-gray-600"
                >
                  <FormattedMessage id="about" defaultMessage="About" />
                </LocalizedLink>
              </li>
            </ul>
            <ul>
              <li>
                <LocalizedLink
                  to="/contact/"
                  className="transition hover:text-gray-600"
                >
                  <FormattedMessage id="contact" defaultMessage="Contact" />
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink
                  to="/imprint/"
                  className="transition hover:text-gray-600"
                >
                  <FormattedMessage id="imprint" defaultMessage="Imprint" />
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink
                  to="/privacy/"
                  className="transition hover:text-gray-600"
                >
                  <FormattedMessage id="privacy" defaultMessage="Privacy" />
                </LocalizedLink>
              </li>
            </ul>
            <ul>
              <li>
                <LocalizedLink
                  to="/about/#career"
                  className="transition hover:text-gray-600"
                >
                  <FormattedMessage id="career" defaultMessage="Career" />
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink
                  to="/press/"
                  className="transition hover:text-gray-600"
                >
                  <FormattedMessage id="press" defaultMessage="Press" />
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink
                    to="/agb/"
                    className="transition hover:text-gray-600"
                >
                  <FormattedMessage id="agb" defaultMessage="AGB" />
                </LocalizedLink>
              </li>
            </ul>
            <ul>
              <li>
                <LocalizedLink
                    to="/aeb/"
                    className="transition hover:text-gray-600"
                >
                  <FormattedMessage id="aeb" defaultMessage="AEB" />
                </LocalizedLink>
              </li>
            </ul>
          </div>
        </div>
        <address className="not-italic font-light">
          {parsedFooterData.address_1}
          <br />
          {parsedFooterData.address_2}
          <br />
          <a
            href={`tel:${parsedFooterData.phone}`}
            className="transition hover:text-gray-600"
          >
            {parsedFooterData.phone}
          </a>
          <br />
          <a
            href={`mailto:${parsedFooterData.email}`}
            className="transition hover:text-gray-600"
          >
            {parsedFooterData.email}
          </a>
        </address>
      </div>
      <div className="flex pt-6">
        <p className="flex-grow">&copy; {new Date().getFullYear()}</p>
        <div className="flex space-x-2 text-2xl">
          {!!parsedFooterData.linkedin && (
            <a
              href={parsedFooterData.linkedin}
              target="_blank"
              rel="noreferrer"
              className="transition hover:text-gray-600"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          )}
          {!!parsedFooterData.xing && (
            <a
              href={parsedFooterData.xing}
              target="_blank"
              rel="noreferrer"
              className="transition hover:text-gray-600"
            >
              <FontAwesomeIcon icon={faXing} />
            </a>
          )}
          {!!parsedFooterData.instagram && (
            <a
              href={parsedFooterData.instagram}
              target="_blank"
              rel="noreferrer"
              className="transition hover:text-gray-600"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default Footer
