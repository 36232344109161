import React from "react"
import LocalizedLink from "../localized-link"
import PropTypes from "prop-types"

const NavigationLink = ({ to, children }) => {
  return (
    <LocalizedLink
      to={to}
      activeClassName="text-wpt-primary"
      partiallyActive
      className="text-lg text-gray-600 font-medium hover:text-wpt-primary px-5 py-3 md:py-0.5"
    >
      {children}
    </LocalizedLink>
  )
}

NavigationLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default NavigationLink
