import React, { useCallback, useContext, useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import NavigationLink from "./navigation-link"
import { Menu } from "@headlessui/react"
import { faBars, faChevronDown } from "@fortawesome/free-solid-svg-icons"
import LocalizedLink from "../localized-link"
import LocationContext from "../../context/location-context"
import clsx from "clsx"
import { siteMetadata } from "../../../gatsby-config"
import WhatWeDoList from "./what-we-do-list"
import { FormattedMessage } from "react-intl"

const Navigation = () => {
  const { languages, defaultLanguage } = siteMetadata

  const location = useContext(LocationContext)

  let delocalizedPath = location.pathname
  languages.forEach(lang => {
    delocalizedPath = delocalizedPath.replace(`/${lang}/`, "/")
  })
  delocalizedPath = delocalizedPath.replace(/\/$/, "")

  let activeLanguage = defaultLanguage
  languages.forEach(lang => {
    if (new RegExp(`/${lang}/`, "i").test(location.pathname)) {
      activeLanguage = lang
    }
  })

  const [showShadow, setShowShadow] = useState(false)
  const handleScroll = useCallback(() => {
    if (window.pageYOffset > 20) {
      setShowShadow(true)
    } else {
      setShowShadow(false)
    }
  }, [])

  useEffect(() => {
    handleScroll()
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])

  return (
    <div
      className={`bg-white fixed w-full z-50 transition-all ${
        showShadow ? "shadow p-2" : "p-5"
      }`}
    >
      {/* Desktop */}
      <div className="hidden container mx-auto items-center md:flex">
        <LocalizedLink to="/" className="hover:opacity-75 transition flex">
          <StaticImage
            height={64}
            placeholder="blurred"
            src="../../images/WPT Logo.png"
            alt="Well Plus Trade Logo"
          />
        </LocalizedLink>
        <nav className="mx-auto text-base divide-x-2">
          <Menu>
            <Menu.Button className="focus:outline-none text-lg text-gray-600 font-medium hover:text-wpt-primary px-5 py-3 md:py-0.5">
              <div
                className={clsx(
                  "inline-flex items-center",
                  location.pathname.includes("/what-we-do/") &&
                    "text-wpt-primary"
                )}
              >
                <FormattedMessage id="whatWeDo" defaultMessage="What we do" />
                <FontAwesomeIcon className="ml-1 fa-xs" icon={faChevronDown} />
              </div>
            </Menu.Button>
            <Menu.Items className="absolute mt-2 outline-none border-none bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
              <WhatWeDoList activeLanguage={activeLanguage} />
            </Menu.Items>
          </Menu>
          <NavigationLink to="/news/">
            <FormattedMessage id="news" defaultMessage="News" />
          </NavigationLink>
          <NavigationLink to="/about/">
            <FormattedMessage id="about" defaultMessage="About us" />
          </NavigationLink>
          <NavigationLink to="/contact/">
            <FormattedMessage id="contact" defaultMessage="Contact" />
          </NavigationLink>
        </nav>
        <div className="divide-x-2">
          {languages.map(lang => (
            <Link
              key={lang}
              to={
                lang === defaultLanguage
                  ? `${delocalizedPath}/`
                  : `/${lang}${delocalizedPath}/`
              }
              className={clsx(
                activeLanguage === lang && "text-wpt-primary font-semibold",
                "px-2 text-gray-600 hover:text-wpt-primary"
              )}
            >
              {lang.toUpperCase()}
            </Link>
          ))}
        </div>
      </div>

      {/* Mobile */}
      <div className="flex items-center md:hidden">
        <div>
          <Menu>
            <Menu.Button className="focus:outline-none py-1 px-3 text-xl">
              <FontAwesomeIcon icon={faBars} />
            </Menu.Button>
            <Menu.Items className="absolute left-0 w-full mt-3 bg-white outline-none">
              <nav className="flex flex-col divide-y-2 shadow">
                <Menu>
                  <Menu.Button className="focus:outline-none text-left text-lg text-gray-600 font-medium hover:text-wpt-primary px-5 py-3 md:py-0.5">
                    <div
                      className={clsx(
                        "inline-flex items-center",
                        location.pathname.includes("/what-we-do/") &&
                          "text-wpt-primary"
                      )}
                    >
                      <FormattedMessage
                        id="whatWeDo"
                        defaultMessage="What we do"
                      />
                      <FontAwesomeIcon
                        className="ml-1 fa-xs"
                        icon={faChevronDown}
                      />
                    </div>
                  </Menu.Button>
                  <Menu.Items className="outline-none bg-gray-100 pl-2">
                    <WhatWeDoList activeLanguage={activeLanguage} />
                  </Menu.Items>
                </Menu>
                <NavigationLink to="/news/">
                  <FormattedMessage id="news" defaultMessage="News" />
                </NavigationLink>
                <NavigationLink to="/about/">
                  <FormattedMessage id="about" defaultMessage="About" />
                </NavigationLink>
                <NavigationLink to="/contact/">
                  <FormattedMessage id="contact" defaultMessage="Contact" />
                </NavigationLink>
              </nav>
            </Menu.Items>
          </Menu>
        </div>
        <LocalizedLink
          to="/"
          className="hover:opacity-75 transition mx-auto flex"
        >
          <StaticImage
            height={48}
            placeholder="blurred"
            src="../../images/WPT Logo.png"
            alt="Well Plus Trade"
          />
        </LocalizedLink>
        <div className="divide-x-2">
          {languages.map(lang => (
            <Link
              key={lang}
              to={
                lang === defaultLanguage
                  ? `${delocalizedPath}/`
                  : `/${lang}${delocalizedPath}/`
              }
              className={clsx(
                activeLanguage === lang && "text-wpt-primary font-semibold",
                "px-2 text-gray-600 hover:text-wpt-primary"
              )}
            >
              {lang.toUpperCase()}
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Navigation
