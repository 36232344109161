import { Link } from "gatsby"
import React, { useContext } from "react"
import PropTypes from "prop-types"
import LocationContext from "../context/location-context"
import { siteMetadata } from "../../gatsby-config"

export const useLocalizeLink = (location, to) => {
  const { pathname } = location
  const { languages } = siteMetadata

  if (!pathname) {
    return null
  }

  const lang = languages.find(
    k => pathname.includes(`/${k}/`) || pathname.includes(`/${k}`)
  )

  if (!lang) {
    return to
  }

  return `/${lang}${to}`
}

const LocalizedLink = ({ to, children, ...props }) => {
  const location = useContext(LocationContext)
  const href = useLocalizeLink(location, to)

  if (/https?:\/\/.*/i.test(to)) {
    return (
      <a href={to} target="_blank" rel="noreferrer" {...props}>
        {children}
      </a>
    )
  }

  return !!href ? (
    <Link to={href} {...props}>
      {children}
    </Link>
  ) : (
    <button {...props}>{children}</button>
  )
}

LocalizedLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default LocalizedLink
