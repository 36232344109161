import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import NavigationLink from "./navigation-link"

const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { slug: { regex: "//what-we-do/[a-z0-9_-]+/?/" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            priority
          }
          fields {
            langKey
          }
        }
      }
    }
  }
`

const WhatWeDoList = ({ activeLanguage }) => {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(query)

  const items = useMemo(
    () =>
      edges
        .filter(({ node }) => node.fields.langKey === activeLanguage)
        .sort(
          (a, b) => a.node.frontmatter.priority - b.node.frontmatter.priority
        )
        .map(({ node }) => ({
          title: node.frontmatter.title,
          slug: node.frontmatter.slug,
        })),
    [activeLanguage, edges]
  )

  return (
    <div className="flex flex-col divide-y-2">
      {items.map(item => {
        return (
          <NavigationLink to={`/what-we-do/${item.slug}/`} key={item.slug}>
            {item.title}
          </NavigationLink>
        )
      })}
    </div>
  )
}

WhatWeDoList.propTypes = {
  activeLanguage: PropTypes.string.isRequired,
}

export default WhatWeDoList
